/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";

const Map = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="container contact-page-map container-map">
        <h2 className="text-center">
          We Cover {rpdata?.dbPrincipal?.miles} Miles Around:{" "}
        </h2>
        <div className="w-100">
          <Tabs
            activeTab="1"
            className=""
            ulClassName=""
            activityClassName="bg-success"
            onClick={(event, tab) => console.log(event, tab)}
          >
            {
              rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    title={item.address}
                    className="text-center"
                    activeClassName="bg-success"
                  >
                    <iframe src={item.url} />
                  </Tab>
                );
              }
              )
            }
            
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Map;
