import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from "./global/navbar";
import PageHeader from "./global/page-header";
import { Helmet } from "react-helmet";
import Footer from "./global/footer";

const Reviews = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <Helmet>
                <title>Reviews | {` ${rpdata?.dbPrincipal?.name}`}</title>
                <meta
                    name="description"
                    content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
                />
                <meta
                    name={` ${rpdata?.dbPrincipal?.name}`}
                    content={` ${rpdata?.dbPrincipal?.name}`}
                ></meta>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
            </Helmet>
            <Navbar />
            <PageHeader
                headertitle="Reviews"
                Subheader="Reviews"
                bgimg={`${rpdata?.gallery?.[5]}`}
            />
            <div className="container pd-top-150 pd-bottom-100">
                <h2 className=" text-center">Our Reviews</h2>
                <div className={`${rpdata?.reviews?.links?.[0]} mt-10`}></div>
            </div>
            <Footer/>

        </div>
    );
};

export default Reviews;
